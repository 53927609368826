/* istanbul ignore file */
import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../../../../components/variations/utils/jsonToProps';
import * as actions from '../../../../actions/vip';

const FEATURE_NAME = 'outside_variations';
const AVIALABLE_QUANTITY = 'available_quantity';
const BULK_SALE_QUANTITY = 'bulk_sale_quantity';

const mapStateToProps = ({
  components: { [FEATURE_NAME]: variations },
  components: { [AVIALABLE_QUANTITY]: available_quantity },
  components: { [BULK_SALE_QUANTITY]: bulk_sale_quantity },
  id,
  shopsDefaultTrackingData,
  platform,
}) =>
  jsonToProps({
    ...variations,
    available_quantity,
    bulk_sale_quantity,
    app: 'vip',
    id,
    shopsDefaultTrackingData,
    platform,
  });

const mapDispatchToProps = dispatch => ({
  showVariationsError: (deviceType, actionKey) => dispatch(actions.showVariationsError(deviceType, actionKey)),
  onSelectVariation: (productId, params, setIsLoading) =>
    dispatch(actions.fetchComponents(productId, params, setIsLoading)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const variations = Variations => enhance(Variations);

export default variations;
