import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import { loadable } from 'nordic/lazy';
import { fetchMeliplusInfo } from '../../../../actions/vip/index';

const FEATURE_NAME = 'loyalty_collapsible_card';

const CollapsableCard = loadable(() => import('../../../../components/collapsable-card'));

const mapStateToProps = state => {
  const loyalty = state?.components?.[FEATURE_NAME] || {};
  const benefits = loyalty.benefits_props || {};

  return {
    data: loyalty.data,
    loading: loyalty.loading,
    error: loyalty.error,
    shippingOriginalAmount: benefits.shipping_original_amount,
    shippingAmount: benefits.shipping_amount,
    cashbackAmount: benefits.cashback_amount,
    financingGroup: benefits.financing_group,
    nameSpace: 'ui-vip',
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMeliplusInfo: () => dispatch(fetchMeliplusInfo()),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(CollapsableCard);
