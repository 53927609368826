import questionsMapper from './questions';
import createQuestionsMapper from './create-questions';
import makeQuestionsMapper from './make-questions';
import a2cMapper from './a2c';
import compatibleMapper from './compatible-compatibilities';
import compatibilitiesMapper from './compatibilities';
import compatsWidgetMapper from './compats-widget';
import couponsMapper from './coupons';
import meliplusMapper from './meliplus';
import cartCongratsMapper from './cart-congrats';
import onePay4AllMapper from './1pay4all';
import wishlistMapper from './wishlist';
import followersMapper from './followers';
import installationsMapper from './installations';
import questionAiMapper from './question-ai';
import collapsibleCardMeliplusMapper from './collapsable-card-meliplus';

const featureMappers = {
  ...questionsMapper,
  ...createQuestionsMapper,
  ...makeQuestionsMapper,
  ...a2cMapper,
  ...compatibilitiesMapper,
  ...compatibleMapper,
  ...compatsWidgetMapper,
  ...couponsMapper,
  ...meliplusMapper,
  ...cartCongratsMapper,
  ...onePay4AllMapper,
  ...wishlistMapper,
  ...followersMapper,
  ...installationsMapper,
  ...questionAiMapper,
  ...collapsibleCardMeliplusMapper,
};

export default featureMappers;
