import APIService from '../../../services/api';

import {
  FETCH_ERROR,
  QUESTIONS_UNREGISTERED_ERROR_FORM,
  REMOVE_RECAPTCHA,
  QUESTIONS_UNREGISTERED_EDIT_DATASTORED,
  QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE,
  MODAL_REQUEST_RESIZING,
  SCROLL_TO_COMPONENT,
  UPDATE_TOKEN_RESPONSE,
  UPDATE_QUOTATION_FORM,
  UPDATE_QUOTATION_CURRENT_STATE,
  UPDATE_GET_UNITS_CURRENT_STATE,
  FETCH_VEHICLE_HISTORY_MODAL,
} from './types';

import {
  getFetchModalQuestions,
  getFetchModalUnregisteredQuestions,
  getOnCreateOnlyQuestion,
  getOnCreateQuestionsUnregistered,
} from '../../getters/vip/vis/questions';
import {
  execFetchModalQuestions,
  execFetchModalUnregisteredQuestions,
  execOnCreateOnlyQuestion,
  execOnCreateQuestionsUnregistered,
} from '../../executors/vip/vis/questions';
import { execFetchContactInfo } from '../../executors/vip/vis/contact-info';
import { getFetchContactInfo } from '../../getters/vip/vis/contact-info';
import { getOnCreateBudgetDemmand, getUpdateBudgetComponents } from '../../getters/vip/vis/budget';
import { execOnCreateBudgetDemmand, execUpdateBudgetComponents } from '../../executors/vip/vis/budget';
import {
  execFetchUnregisteredQuotations,
  execGetModelUnits,
  execGetPriceModelUnits,
  execGetUnitsLTR,
  execOnCreateQuotation,
  execOnCreateUnregisteredQuotation,
  execOnShowPrice,
} from '../../executors/vip/vis/quotation';
import {
  getOnCreateQuotation,
  getOnCreateUnregisteredQuotation,
  getOnShowPrice,
  getterFetchUnregisteredQuotations,
  getterModelUnits,
  gettertPriceModelUnits,
  gettertUnitsLTR,
} from '../../getters/vip/vis/quotation';

const onCreateQuestionsUnregistered = ({
  component,
  action,
  itemId,
  form,
  outPutValues,
  hasErrors,
  recaptchaV2Token,
  track,
  origin,
  recaptchaSiteKey,
}) => (dispatch, getState) =>
  execOnCreateQuestionsUnregistered(
    dispatch,
    getOnCreateQuestionsUnregistered(
      component,
      action,
      itemId,
      form,
      outPutValues,
      hasErrors,
      recaptchaV2Token,
      track,
      origin,
      recaptchaSiteKey,
      getState,
    ),
  );

const fetchContactInfo = ({
  component,
  itemId,
  contactType,
  action,
  recaptchav2token,
  ajaxBackendParams,
  recaptchaActionType,
  recaptchaSiteKey,
  params = {},
}) => (dispatch, getState) =>
  execFetchContactInfo(
    dispatch,
    getFetchContactInfo(
      component,
      itemId,
      contactType,
      action,
      recaptchav2token,
      ajaxBackendParams,
      recaptchaActionType,
      recaptchaSiteKey,
      params,
      getState,
    ),
  );

const onSetQuestionsUnregisteredFormError = ({ form, component }) => dispatch => {
  dispatch({
    type: QUESTIONS_UNREGISTERED_ERROR_FORM,
    payload: { form, component },
  });
};

const fetchModalQuestions = (itemId, variationId, actionType) => (dispatch, getState) =>
  execFetchModalQuestions(dispatch, getFetchModalQuestions(itemId, variationId, actionType, getState));

const fetchModalUnregisteredQuestions = (itemId, variationId) => (dispatch, getState) =>
  execFetchModalUnregisteredQuestions(dispatch, getFetchModalUnregisteredQuestions(itemId, variationId, getState));

const removeRecaptcha = component => dispatch => {
  dispatch({ type: REMOVE_RECAPTCHA, payload: { component } });
};

const onCreateOnlyQuestion = ({ itemId, text, track, component, checkbox }) => (dispatch, getState) =>
  execOnCreateOnlyQuestion(dispatch, getOnCreateOnlyQuestion(itemId, text, track, component, checkbox, getState));

const onChangeUnregisteredQuestionValue = ({ input, value }) => dispatch => {
  dispatch({ type: QUESTIONS_UNREGISTERED_ON_CHANGE_VALUE, payload: { input, value } });
};

const onScrollComponent = ({ component, focus }) => dispatch => {
  dispatch({ type: SCROLL_TO_COMPONENT, payload: { component, focus } });
};

const updateTokenResponse = ({ component, token }) => dispatch => {
  dispatch({ type: UPDATE_TOKEN_RESPONSE, payload: { component, token } });
};

const onEnableStorageDataInQuestionsUnregisteredForm = ({ canEdit, isEditing, component }) => dispatch => {
  dispatch({ type: QUESTIONS_UNREGISTERED_EDIT_DATASTORED, canEdit, isEditing, component });
  dispatch({ type: MODAL_REQUEST_RESIZING });
};

const redirectToLogin = ({ itemId, loginType, featureName }) => () => {
  APIService.redirectToLogin(itemId, loginType, featureName);
};

const updateBudgetComponents = message => (dispatch, getState) =>
  execUpdateBudgetComponents(dispatch, getUpdateBudgetComponents(message, getState));

const onCreateBudgetDemmand = ({ itemId, text, track }) => (dispatch, getState) =>
  execOnCreateBudgetDemmand(dispatch, getOnCreateBudgetDemmand(itemId, text, track, getState));

const getModelUnits = (modelId, deviceType, currentState) => (dispatch, getState) =>
  execGetModelUnits(dispatch, getterModelUnits(modelId, deviceType, currentState, getState));

const getPriceModelUnits = (modelId, deviceType, currentState) => (dispatch, getState) =>
  execGetPriceModelUnits(dispatch, gettertPriceModelUnits(modelId, deviceType, currentState, getState));

const getUnitsLTR = (modelId, deviceType, currentState) => (dispatch, getState) =>
  execGetUnitsLTR(dispatch, gettertUnitsLTR(modelId, deviceType, currentState, getState));

const fetchUnregisteredQuotations = (modelId, deviceType, currentState) => (dispatch, getState) =>
  execFetchUnregisteredQuotations(
    dispatch,
    getterFetchUnregisteredQuotations(modelId, deviceType, currentState, getState),
  );

const updateQuotationForm = form => dispatch => {
  dispatch({ type: UPDATE_QUOTATION_FORM, payload: form });
};

const onCreateQuotation = ({ unitId, recaptchaTokenV2, track, recaptchaSiteKey }) => (dispatch, getState) =>
  execOnCreateQuotation(dispatch, getOnCreateQuotation(unitId, recaptchaTokenV2, track, recaptchaSiteKey, getState));

const onShowPrice = ({ unitId, modelId, deviceType, currentState, recaptchaTokenV2, recaptchaSiteKey }) => (
  dispatch,
  getState,
) =>
  execOnShowPrice(
    dispatch,
    getOnShowPrice(unitId, modelId, deviceType, currentState, recaptchaTokenV2, recaptchaSiteKey, getState),
  );

const onCreateUnregisteredQuotation = ({
  unitId,
  recaptchaTokenV2,
  form,
  track,
  recaptchaSiteKey,
  currentState,
  handleClose,
}) => (dispatch, getState) =>
  execOnCreateUnregisteredQuotation(
    dispatch,
    getOnCreateUnregisteredQuotation(
      unitId,
      recaptchaTokenV2,
      form,
      track,
      recaptchaSiteKey,
      currentState,
      handleClose,
      getState,
    ),
  );

const updateQuotationCurrentState = currentState => dispatch => {
  dispatch({ type: UPDATE_QUOTATION_CURRENT_STATE, payload: currentState });
};

const updateLTRUnitsCurrentState = currentState => dispatch => {
  dispatch({ type: UPDATE_GET_UNITS_CURRENT_STATE, payload: currentState });
};

const fetchVehicleHistoryModal = itemId => dispatch => {
  APIService.getVehicleHistoryModal(itemId)
    .then(payload => {
      dispatch({ type: FETCH_VEHICLE_HISTORY_MODAL, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

export {
  onCreateQuestionsUnregistered,
  fetchContactInfo,
  updateBudgetComponents,
  onSetQuestionsUnregisteredFormError,
  fetchModalQuestions,
  fetchModalUnregisteredQuestions,
  onEnableStorageDataInQuestionsUnregisteredForm,
  removeRecaptcha,
  onCreateOnlyQuestion,
  onChangeUnregisteredQuestionValue,
  onScrollComponent,
  redirectToLogin,
  updateTokenResponse,
  onCreateBudgetDemmand,
  getModelUnits,
  getUnitsLTR,
  fetchUnregisteredQuotations,
  updateQuotationForm,
  onCreateQuotation,
  onCreateUnregisteredQuotation,
  updateQuotationCurrentState,
  updateLTRUnitsCurrentState,
  onShowPrice,
  getPriceModelUnits,
  fetchVehicleHistoryModal,
};
