import APIService from '../../../services/api';
import { processRequest, requestQueue } from '../../utils/addToCartRequestQueue';
import { ADD_TO_CART_UPDATE_COMPLETE, ADD_TO_CART_UPDATE_ERROR, ADD_TO_CART_UPDATE_START } from '../../vip/types';

const FEEDBACK_STYLE_ERROR = 'ERROR';

const execAddToCartUpdate = (dispatch, args) => {
  const { data, params, onSuccess, onError } = args;
  const request = () => {
    dispatch({ type: ADD_TO_CART_UPDATE_START });
    requestQueue.isRequestInProgress = true;

    APIService.addToCartUpdate(data, params)
      .then(payload => {
        const callback = () => {
          dispatch({ type: ADD_TO_CART_UPDATE_COMPLETE, payload });
          if (payload.feedback && payload.feedback.style === FEEDBACK_STYLE_ERROR) {
            onError();
          } else {
            onSuccess();
          }
        };
        processRequest(callback);
      })
      .catch(e => {
        const callback = () => {
          dispatch({ type: ADD_TO_CART_UPDATE_ERROR, error: e });
          if (onError) {
            onError();
          }
        };
        processRequest(callback);
      });
  };

  if (requestQueue.isRequestInProgress) {
    requestQueue.pendingRequest = request;
  } else {
    request();
  }
};

export { execAddToCartUpdate };
