import { trackEvent } from '@vpp-frontend-components/common';
import { groupedModelUnits } from '../../../../components/quotations/helpers';
import APIService from '../../../../services/api';
import {
  CREATE_QUOTATION_COMPLETE,
  CREATE_QUOTATION_ERROR,
  CREATE_QUOTATION_SHOW_PRICE,
  CREATE_QUOTATION_START,
  FETCH_GET_UNITS_COMPLETE,
  FETCH_GET_UNITS_ERROR,
  FETCH_GET_UNITS_START,
  FETCH_QUOTATION_COMPLETE,
  FETCH_QUOTATION_ERROR,
  FETCH_QUOTATION_PRICE,
  FETCH_QUOTATION_START,
  MODAL_REQUEST_START,
  QUOTATION_ERROR_EXCEEDS_LIMIT,
  SHOW_RECAPTCHA,
  UPDATE_GET_UNITS_CURRENT_STATE,
  UPDATE_QUOTATION_CURRENT_STATE,
  UPDATE_QUOTATION_FORM,
} from '../../../vip/vis/types';
import handleRedirect from '../../../utils/handle-redirect';
import { onLoadRecaptchav3Token } from '../../../../components/recaptcha';
import { gettertPriceModelUnits } from '../../../getters/vip/vis/quotation';
import { IS_POSTING, QUOTATION_ERRORS } from '../../../../components/unregistered-modal/utils/constants';

const getSelectionState = (modelId, modelUnits) => ({
  selectedModelId: modelId,
  selectedUnit: modelUnits[modelId][0],
});

const execGetModelUnits = (dispatch, args) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_QUOTATION_START });
  const { app, id, quotation, picture_config, modelId, deviceType, currentState } = args;
  const currentUnits = quotation ? quotation.model_units : {};

  if (quotation && quotation.model_units && quotation.model_units[modelId]) {
    dispatch({
      type: UPDATE_QUOTATION_CURRENT_STATE,
      payload: {
        ...currentState,
        ...getSelectionState(modelId, quotation.model_units),
        isFetching: false,
      },
    });
    return;
  }

  APIService.getModelUnits(id, { app, model_id: modelId, deviceType })
    .then(payload => {
      const quotationComponent = payload.components.find(component => component.id === 'quotation');
      const model_units = { ...currentUnits, ...groupedModelUnits(quotationComponent.model_units, picture_config) };
      const exceedQuotationLimit = quotationComponent?.snackbar?.quotation_limit;

      dispatch({
        type: FETCH_QUOTATION_COMPLETE,
        payload: { ...quotationComponent, model_units },
      });
      const defaultModelId = modelId || quotationComponent.models[0].id;
      dispatch({
        type: UPDATE_QUOTATION_CURRENT_STATE,
        payload: {
          ...currentState,
          isFetching: false,
          ...getSelectionState(defaultModelId, model_units),
        },
      });
      if (exceedQuotationLimit) {
        dispatch({ type: QUOTATION_ERROR_EXCEEDS_LIMIT, payload: exceedQuotationLimit });
      }
    })
    .catch(e => dispatch({ type: FETCH_QUOTATION_ERROR, error: e }));
};

const execGetPriceModelUnits = (dispatch, args) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_QUOTATION_START });
  const { app, id, quotation, picture_config, modelId, deviceType, currentState } = args;
  const currentUnits = quotation ? quotation.model_units : {};

  APIService.getModelUnits(id, { app, model_id: modelId, deviceType })
    .then(payload => {
      const quotationComponent = payload.components.find(component => component.id === 'quotation');
      const model_units = { ...currentUnits, ...groupedModelUnits(quotationComponent.model_units, picture_config) };
      dispatch({
        type: FETCH_QUOTATION_PRICE,
        payload: { ...quotationComponent, model_units },
      });
      const defaultModelId = modelId || quotationComponent.models[0].id;
      dispatch({
        type: UPDATE_QUOTATION_CURRENT_STATE,
        payload: {
          ...currentState,
          isFetching: false,
          ...getSelectionState(defaultModelId, model_units),
        },
      });
      dispatch({ type: CREATE_QUOTATION_SHOW_PRICE, payload: 'show_price' });
      trackEvent(quotationComponent.quotation_track);
    })
    .catch(e => dispatch({ type: FETCH_QUOTATION_ERROR, error: e }));
};

const execGetUnitsLTR = (dispatch, args) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_GET_UNITS_START });
  const { app, id, multifamily_modal, picture_config, modelId, deviceType, currentState } = args;
  const currentUnits = multifamily_modal ? multifamily_modal.model_units : {};

  if (multifamily_modal?.model_units?.[modelId]) {
    dispatch({
      type: UPDATE_GET_UNITS_CURRENT_STATE,
      payload: {
        ...currentState,
        ...getSelectionState(modelId, multifamily_modal.model_units),
        isFetching: false,
      },
    });
    return;
  }

  APIService.getUnitsLTR(id, { app, model_id: modelId, deviceType })
    .then(payload => {
      const ltrComponent = payload.components.find(component => component.id === 'check_units');
      const model_units = { ...currentUnits, ...groupedModelUnits(ltrComponent.model_units, picture_config) };
      dispatch({
        type: FETCH_GET_UNITS_COMPLETE,
        payload: { ...ltrComponent, model_units },
      });
      const defaultModelId = modelId || ltrComponent.models[0].id;
      dispatch({
        type: UPDATE_GET_UNITS_CURRENT_STATE,
        payload: {
          ...currentState,
          isFetching: false,
          ...getSelectionState(defaultModelId, model_units),
        },
      });
    })
    .catch(e => dispatch({ type: FETCH_GET_UNITS_ERROR, error: e }));
};

const execFetchUnregisteredQuotations = (dispatch, args) => {
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_QUOTATION_START });
  const { app, id, quotation, picture_config, modelId, deviceType, currentState } = args;
  const currentUnits = quotation ? quotation.model_units : {};

  if (quotation && quotation.model_units && quotation.model_units[modelId]) {
    dispatch({
      type: UPDATE_QUOTATION_CURRENT_STATE,
      payload: {
        ...currentState,
        ...getSelectionState(modelId, quotation.model_units),
        isFetching: false,
      },
    });
    return;
  }

  APIService.getUnregisteredQuotations(id, { app, model_id: modelId, deviceType })
    .then(payload => {
      const quotationComponent = payload.components.find(component => component.id === 'quotation');
      const model_units = { ...currentUnits, ...groupedModelUnits(quotationComponent.model_units, picture_config) };
      dispatch({
        type: FETCH_QUOTATION_COMPLETE,
        payload: { ...quotationComponent, model_units },
      });
      const defaultModelId = modelId || quotationComponent.models[0].id;
      dispatch({
        type: UPDATE_QUOTATION_CURRENT_STATE,
        payload: {
          ...currentState,
          isFetching: false,
          ...getSelectionState(defaultModelId, model_units),
        },
      });
    })
    .catch(e => dispatch({ type: FETCH_QUOTATION_ERROR, error: e }));
};

const execOnCreateQuotation = (dispatch, args) => {
  const { id, app, unitId, recaptchaTokenV2, track, recaptchaSiteKey } = args;
  dispatch({ type: CREATE_QUOTATION_START });
  const existingArrayString = localStorage.getItem('quoted-units');
  const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];

  const getRecaptchaTokenv3 = new Promise(resolve => {
    if (!recaptchaTokenV2) {
      onLoadRecaptchav3Token(recaptchaSiteKey, 'quotation', recaptchaTokenv3 => resolve(recaptchaTokenv3));
    } else {
      resolve(null);
    }
  });

  getRecaptchaTokenv3
    .then(recaptchaTokenV3 => {
      APIService.createQuotation(
        { itemId: id, unitId, recaptchaTokenV2, action: 'quotation', recaptchaTokenV3 },
        { app },
      )
        .then(payload => {
          if (payload.success === true) {
            trackEvent(track);
            if (!existingArray.includes(unitId)) {
              existingArray.push(unitId);
              localStorage.setItem('quoted-units', JSON.stringify(existingArray));
            }
          }
          dispatch({ type: CREATE_QUOTATION_COMPLETE });
          if (payload.redirect) {
            handleRedirect(payload, true);
          }
          if (payload.success === true && !payload.redirect) {
            dispatch({ type: CREATE_QUOTATION_SHOW_PRICE, payload: 'congrats' });
          }
          if (payload.recaptcha) {
            dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component: 'quotation' } });
          } else if (!payload.success && !payload.has_validation_errors) {
            dispatch({ type: CREATE_QUOTATION_ERROR, error: payload });
          }
        })
        .catch(e => {
          dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
        });
    })
    .catch(e => {
      dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
    });
};

const execOnShowPrice = (dispatch, args) => {
  const { id, app, unitId, modelId, deviceType, currentState, recaptchaTokenV2, recaptchaSiteKey, getState } = args;

  const getRecaptchaTokenv3 = new Promise(resolve => {
    if (!recaptchaTokenV2) {
      onLoadRecaptchav3Token(recaptchaSiteKey, 'quotation', recaptchaTokenv3 => resolve(recaptchaTokenv3));
    } else {
      resolve(null);
    }
  });

  getRecaptchaTokenv3.then(recaptchaTokenV3 => {
    APIService.createQuotationPrice(
      {
        itemId: id,
        unitId,
        recaptchaTokenV2,
        action: 'quotation',
        recaptchaTokenV3,
        quotationType: 'show-prices',
      },
      { app },
    )
      .then(payload => {
        if (payload.success === true) {
          execGetPriceModelUnits(dispatch, gettertPriceModelUnits(modelId, deviceType, currentState, getState));
        }
        if (payload.recaptcha) {
          dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component: 'quotation' } });
        } else if (!payload.success && !payload.has_validation_errors) {
          dispatch({ type: CREATE_QUOTATION_ERROR, error: payload });
        }
      })
      .catch(e => {
        dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
      });
  });
};

const execOnCreateUnregisteredQuotation = (dispatch, args) => {
  const { id, app, unitId, recaptchaTokenV2, form, track, recaptchaSiteKey, currentState, handleClose } = args;
  dispatch({ type: CREATE_QUOTATION_START });

  const getRecaptchaTokenv3 = new Promise(resolve => {
    if (!recaptchaTokenV2) {
      onLoadRecaptchav3Token(recaptchaSiteKey, 'quotation', recaptchaTokenv3 => resolve(recaptchaTokenv3));
    } else {
      resolve(null);
    }
  });

  getRecaptchaTokenv3
    .then(recaptchaTokenV3 => {
      APIService.createUnregisteredQuotation(
        { itemId: id, unitId, recaptchaTokenV2, recaptchaTokenV3, action: 'quotation', form },
        { app },
      )
        .then(payload => {
          localStorage.setItem(IS_POSTING, true);
          if (payload.success === true) {
            trackEvent(track);
            const existingArrayString = localStorage.getItem('quoted-units');
            const existingArray = existingArrayString ? JSON.parse(existingArrayString) : [];
            if (!existingArray.includes(unitId)) {
              existingArray.push(unitId);
              localStorage.setItem('quoted-units', JSON.stringify(existingArray));
            }
            localStorage.removeItem(IS_POSTING);

            if (!payload.redirect) {
              dispatch({ type: CREATE_QUOTATION_SHOW_PRICE, payload: 'congrats' });
              if (currentState) {
                dispatch({ type: UPDATE_QUOTATION_CURRENT_STATE, payload: { ...currentState, step: 0 } });
              }
              if (handleClose) {
                handleClose();
              }
            }
          }
          dispatch({ type: CREATE_QUOTATION_COMPLETE });
          if (payload.redirect) {
            handleRedirect(payload, true);
          }
          if (payload.form) {
            dispatch({ type: UPDATE_QUOTATION_FORM, payload: payload.form.form });
          }
          if (payload.recaptcha) {
            dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component: 'quotation' } });
          } else if (!payload.success && !payload.has_validation_errors) {
            if (payload.error_code === QUOTATION_ERRORS.PRECONDITION_FAILED) {
              dispatch({ type: QUOTATION_ERROR_EXCEEDS_LIMIT, payload });
            } else {
              localStorage.removeItem(IS_POSTING);
              dispatch({ type: CREATE_QUOTATION_ERROR, error: payload });
            }
          }
        })
        .catch(e => {
          localStorage.removeItem(IS_POSTING);
          dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
        });
    })
    .catch(e => {
      localStorage.removeItem(IS_POSTING);
      dispatch({ type: CREATE_QUOTATION_ERROR, error: e });
    });
};

export {
  execGetModelUnits,
  execGetPriceModelUnits,
  execGetUnitsLTR,
  execFetchUnregisteredQuotations,
  execOnCreateQuotation,
  execOnShowPrice,
  execOnCreateUnregisteredQuotation,
};
