const getOnCreateQuestion = (itemId, text, track, getState) => {
  const { app } = getState();
  return { app, itemId, text, track };
};

const getOnCreateQuestionInView = (itemId, text, track, make_only_question, getState) => {
  const { app } = getState();
  return { app, itemId, text, track, make_only_question };
};

const getFetchMoreQuestionsDesktop = (itemId, offset, getState) => {
  const { app } = getState();
  return { app, itemId, offset };
};

const getFetchMoreQuestionsMobile = (itemId, offset, getState) => {
  const { app } = getState();
  return { app, itemId, offset };
};

export { getOnCreateQuestion, getOnCreateQuestionInView, getFetchMoreQuestionsDesktop, getFetchMoreQuestionsMobile };
