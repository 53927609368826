import { trackEvent } from '@vpp-frontend-components/common';
import APIService from '../../../../services/api';
import handleRedirect from '../../../utils/handle-redirect';
import {
  CREATE_QUESTIONS_UNREGISTERED_COMPLETE,
  CREATE_QUESTIONS_UNREGISTERED_ERROR,
  CREATE_QUESTIONS_UNREGISTERED_START,
  FETCH_COMPONENT,
  FETCH_MODAL_QUESTIONS_COMPLETE,
  FETCH_MODAL_QUESTIONS_ERROR,
  FETCH_MODAL_QUESTIONS_START,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR,
  FETCH_MODAL_UNREGISTERED_QUESTIONS_START,
  MODAL_REQUEST_CLOSE,
  MODAL_REQUEST_RESIZING,
  MODAL_REQUEST_START,
  SHOW_RECAPTCHA,
} from '../../../vip/vis/types';
import { saveForm } from '../../../../form/local-storage/unregistered-questions-local-storage';
import { onLoadRecaptchav3Token } from '../../../../components/recaptcha';
import {
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_ERROR,
  CREATE_QUESTION_START,
  SHOW_SNACKBAR,
} from '../../../vip/types';
import redirectReauthUrl from '../../../utils/redirect-reauth-url';

const dispatchCreateQuestionUnregistered = (
  { component, itemId, form, outPutValues, recaptchaV2Token, app, track, origin, action },
  dispatch,
) => recaptchaTokenv3 => {
  dispatch({ type: FETCH_COMPONENT, isFetchingData: true, component });
  return APIService.createQuestionsUnregistered({
    itemId,
    form: outPutValues,
    recaptchaTokenv3,
    recaptchaV2Token,
    params: { app, origin },
    action,
  })
    .then(payload => {
      dispatch({ type: FETCH_COMPONENT, isFetchingData: false, component });
      if (payload.success) {
        if (!payload.is_repeated) {
          trackEvent(track);
        }
        saveForm({ input: payload.unregistered_questions.form });
        dispatch({ type: MODAL_REQUEST_CLOSE });
      } else if (payload.has_validation_errors) {
        payload.display_message = null;
      }
      // eslint-disable-next-line promise/always-return
      if (payload.redirect) {
        handleRedirect(payload);
      } else if (payload.recaptcha) {
        dispatch({ type: SHOW_RECAPTCHA, payload: { ...payload, component } });
        dispatch({ type: MODAL_REQUEST_RESIZING });
      } else {
        dispatch({ type: CREATE_QUESTIONS_UNREGISTERED_COMPLETE, payload, component });
      }
    })
    .catch(e => {
      dispatch({ type: CREATE_QUESTIONS_UNREGISTERED_ERROR, error: e, form, component });
    });
};

const execOnCreateQuestionsUnregistered = (dispatch, args) => {
  const {
    app,
    component,
    action,
    itemId,
    form,
    outPutValues,
    hasErrors,
    recaptchaV2Token,
    track,
    origin,
    recaptchaSiteKey,
  } = args;
  dispatch({ type: CREATE_QUESTIONS_UNREGISTERED_START, form, itemId, hasErrors, component });
  if (recaptchaV2Token) {
    dispatchCreateQuestionUnregistered(
      { component, itemId, form, outPutValues, recaptchaV2Token, app, track, origin, action },
      dispatch,
    )();
  } else {
    onLoadRecaptchav3Token(
      recaptchaSiteKey,
      action,
      dispatchCreateQuestionUnregistered(
        { component, itemId, form, outPutValues, app, track, origin, action },
        dispatch,
      ),
      e => dispatch({ type: CREATE_QUESTIONS_UNREGISTERED_ERROR, error: e, form, component }),
    );
  }
};

const execFetchModalQuestions = (dispatch, args) => {
  const { app, itemId, variationId, actionType } = args;
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_MODAL_QUESTIONS_START });

  APIService.getModalQuestions(itemId, { app, variation_id: variationId, action_type: actionType })
    .then(payload => {
      dispatch({
        type: FETCH_MODAL_QUESTIONS_COMPLETE,
        payload,
      });
    })
    .catch(e => dispatch({ type: FETCH_MODAL_QUESTIONS_ERROR, error: e }));
};

const execFetchModalUnregisteredQuestions = (dispatch, args) => {
  const { app, itemId, variationId } = args;
  dispatch({ type: MODAL_REQUEST_START });
  dispatch({ type: FETCH_MODAL_UNREGISTERED_QUESTIONS_START });
  APIService.getModalUnregisteredQuestions(itemId, { app, variation_id: variationId })
    .then(payload => {
      dispatch({
        type: FETCH_MODAL_UNREGISTERED_QUESTIONS_COMPLETE,
        payload,
      });
    })
    .catch(e => dispatch({ type: FETCH_MODAL_UNREGISTERED_QUESTIONS_ERROR, error: e }));
};

const execOnCreateOnlyQuestion = (dispatch, args) => {
  const { app, vertical, itemId, text, track, component, checkbox } = args;
  dispatch({ type: CREATE_QUESTION_START, itemId, component });
  APIService.createQuestion(itemId, text, { app, make_only_question: true, checkbox, vertical })
    .then(payload => {
      if (payload) {
        if (payload.success === true) {
          if (!payload.is_repeated) {
            trackEvent(track);
          }
          dispatch({ type: MODAL_REQUEST_CLOSE });
        }

        if (payload.redirect) {
          handleRedirect(payload, true);
        } else {
          dispatch({ type: CREATE_QUESTION_COMPLETE, payload, component });
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.display_message.text,
              type: 'success',
              called_from: 'vis',
            },
          });
        }
      }
    })
    .catch(e => {
      if (redirectReauthUrl(e)) {
        return;
      }

      dispatch({ type: MODAL_REQUEST_CLOSE });
      if (e.response && e.response.data && e.response.data.displayMessage) {
        dispatch({ type: CREATE_QUESTION_ERROR, component, error: e.response.data.displayMessage });
        dispatch({
          type: SHOW_SNACKBAR,
          params: {
            message: e.response.data.displayMessage,
            type: 'error',
            called_from: 'vis',
          },
        });
        return;
      }
      dispatch({ type: CREATE_QUESTION_ERROR, component, error: e });
    });
};

export {
  dispatchCreateQuestionUnregistered,
  execOnCreateQuestionsUnregistered,
  execFetchModalQuestions,
  execFetchModalUnregisteredQuestions,
  execOnCreateOnlyQuestion,
};
