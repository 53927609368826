const getFetchUpdatedCoupon = getState => {
  const { id, app, selected_offer_type: offer_type } = getState();
  return { id, app, offer_type };
};

const getPostCouponActivate = (track, params, getState) => {
  const { app, siteId, components } = getState();
  const { available_quantity, bulk_sale_quantity, main_actions } = components;
  const quantity = available_quantity?.picker?.selected ?? bulk_sale_quantity?.selected;
  const item_id = main_actions?.form?.item_id;

  return { app, siteId, quantity, item_id, params, track };
};

export { getFetchUpdatedCoupon, getPostCouponActivate };
