const getToggleFollowSeller = getState => {
  const { app, components, siteId } = getState();
  const { seller_data: sellerData } = components;
  const { followers } = sellerData.components.find(el => el.id === 'seller_header');
  const params = new URLSearchParams(followers.query_params);
  params.append('app', app);
  params.append('site_id', siteId);
  return { params, followers };
};

export { getToggleFollowSeller };
