import { QUESTIONS_AI_DATADOG_KEY_PREFIX } from '../../../services/frontend-statsd/config/allowed-keys';
import { BUTTON_TYPES_TAG_VALUES } from '../../../services/frontend-statsd/config/allowed-tags';
import getSelectedAttributes from '../../utils/get-selected-attributes';

const getUpdateComponentsBottomSheet = getState => {
  const {
    id,
    filters: pdp_filters,
    vip_filters,
    components,
    app,
    platform,
    selectedShippingOptionId,
    hasDeferredErrors,
  } = getState();
  const { available_quantity, bulk_sale_quantity } = components;
  const quantity = available_quantity?.picker?.selected ?? bulk_sale_quantity?.selected;
  const attributes = getSelectedAttributes(components);
  return {
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    selectedShippingOptionId,
    attributes,
    hasDeferredErrors,
  };
};

const getShowQuestionsAiModal = (target, isDismissible, disabled, called_from, source, getState) => {
  const { app } = getState();
  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      button_id: `${called_from}.${BUTTON_TYPES_TAG_VALUES.ASK_QUESTIONS_AI}`,
      referer_app: app,
      is_disabled: Boolean(disabled),
      called_from,
      source,
    },
  };
  return { target, isDismissible, statsdConfig };
};

const getShowAddToCartModal = (itemId, labelText, getState) => {
  const { components, shopsDefaultTrackingData } = getState();
  const { available_quantity, bulk_sale_quantity, main_actions } = components;
  const { form } = main_actions;
  const quantity = available_quantity?.picker?.selected ?? bulk_sale_quantity?.selected;
  const data = {
    quantity,
    ...form,
    item_id: itemId,
  };
  return { data, itemId, labelText, shopsDefaultTrackingData };
};

export { getUpdateComponentsBottomSheet, getShowQuestionsAiModal, getShowAddToCartModal };
