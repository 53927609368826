import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';

const FEATURE_NAME = 'header_cpg';

const mapStateToProps = ({ components: { [FEATURE_NAME]: header_cpg } }) => header_cpg;

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(mapStateToProps),
);

const headerCpg = CPG => enhance(CPG);

export default headerCpg;
