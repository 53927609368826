import get from 'lodash/get';
import connect from '../../../../store/connect';
import { TrackPageVariation } from '../../../../components/tracking';

const FEATURE_VARIATIONS = 'variations';
const mapStateToProps = ({ track, components }) => ({
  melidata_event: track ? track.melidata_event : null,
  analytics_event: track ? track.analytics_event : null,
  variation_id: get(components, `${FEATURE_VARIATIONS}.variation_id`, null),
});

export default connect(
  mapStateToProps,
  null,
  'tracking_variations',
)(TrackPageVariation);
