import React from 'react';
import { shape, bool, arrayOf } from 'prop-types';
import cn from 'classnames';
import Grid from '../grid';

const namespace = 'ui-vip-grouped-header';

const GroupedHeader = ({ components, groupedComponents, withStoreHeader, withCompatsWidget }) => (
  <Grid.Row
    className={cn(
      namespace,
      { [`${namespace}__header-store`]: withStoreHeader },
      { [`${namespace}__header--with-compats`]: withCompatsWidget },
    )}
  >
    {components.map(component => {
      if (component) {
        const ReactComponent = groupedComponents[component.type];
        return ReactComponent && <ReactComponent key={component.id} {...component} />;
      }
      return null;
    })}
  </Grid.Row>
);

GroupedHeader.propTypes = {
  components: arrayOf(shape({})).isRequired,
  groupedComponents: shape().isRequired,
  withStoreHeader: bool,
  withCompatsWidget: bool,
};

GroupedHeader.defaultProps = {
  withStoreHeader: false,
  withCompatsWidget: false,
};

export default GroupedHeader;
