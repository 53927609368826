const redirectReauthUrl = requestError => {
  if (requestError?.response?.status === 401 && !!requestError?.response?.data?.require_reauth) {
    const reauthUrl = requestError.response.data.url;
    if (reauthUrl) {
      window.location.href = reauthUrl;
      return true;
    }
  }
  return false;
};

export default redirectReauthUrl;
