import APIService from '../../../services/api';
import { FETCH_ON_MELIPLUS_UPDATE_START, FETCH_ON_MELIPLUS_UPDATE_COMPLETE, FETCH_ERROR } from '../../vip/types';

const execFetchItemOnMeliplusUpdate = (dispatch, args) => {
  const { id, platform, app, pdp_filters, vip_filters, attributes, quantity, updateAction } = args;

  dispatch({
    type: FETCH_ON_MELIPLUS_UPDATE_START,
  });

  APIService.getProductUpdateMeliplus(id, {
    id,
    platform,
    attributes,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    update_action: updateAction,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_MELIPLUS_UPDATE_COMPLETE, payload });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

export { execFetchItemOnMeliplusUpdate };
