import APIService from '../../../services/api';
import { getDefaultErrorMessage } from '../../utils/getDefaultErrorMessage';
import trackIfNecessary from '../../utils/track-if-necessary';
import {
  FETCH_ERROR,
  FETCH_ON_COUPON_UPDATE_COMPLETE,
  FETCH_ON_COUPON_UPDATE_ERROR,
  FETCH_ON_COUPON_UPDATE_START,
  SHOW_SNACKBAR,
} from '../../vip/types';

// HANDLES
const handlePostCouponActivateError = (dispatch, siteId, error) => {
  dispatch({
    type: SHOW_SNACKBAR,
    params: {
      message: error?.response?.data?.display_message ?? getDefaultErrorMessage(siteId),
      type: 'error',
      delay: 6000,
      called_from: 'coupon_summary',
    },
  });
};

// EXECUTES
const execFetchUpdatedCoupon = (dispatch, args) => {
  const { id, app, offer_type } = args;
  dispatch({ type: FETCH_ON_COUPON_UPDATE_START, id });
  APIService.getProductUpdateCoupon(id, { app, offer_type })
    .then(payload => {
      dispatch({ type: FETCH_ON_COUPON_UPDATE_COMPLETE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

const execPostCouponActivate = (dispatch, args) => {
  const { app, siteId, item_id, quantity, params, track } = args;
  trackIfNecessary(track);
  dispatch({ type: FETCH_ON_COUPON_UPDATE_START, id: item_id });
  APIService.postProductCouponActivate(item_id, { app, quantity, ...params })
    .then(payload => {
      trackIfNecessary(payload?.components?.coupon_summary?.awareness?.track);
      dispatch({ type: FETCH_ON_COUPON_UPDATE_COMPLETE, payload });
    })
    .catch(e => {
      dispatch({ type: FETCH_ON_COUPON_UPDATE_ERROR });
      handlePostCouponActivateError(dispatch, siteId, e);
    });
};

export { execFetchUpdatedCoupon, execPostCouponActivate, handlePostCouponActivateError };
