import { VPP_SEQUENCER_DATADOG_KEY_PREFIX } from '../../../services/frontend-statsd/config/allowed-keys';
import { UNKNOWN_VALUE } from '../../../services/frontend-statsd/config/allowed-tags';

const getTriggerBuyNowOnePayForAll = (actionKey, getState) => {
  const { components, siteId } = getState();
  const { available_quantity, bulk_sale_quantity, main_actions } = components;
  const { form, actions } = main_actions;
  const quantity = available_quantity?.picker?.selected ?? bulk_sale_quantity?.selected;
  return { actionKey, siteId, form, actions, quantity };
};

const getTriggerSequencer = (actionKey, getState) => {
  const { app, components, preload, siteId } = getState();
  const { main_actions } = components;
  const { actions } = main_actions;

  const action = actions.find(act => actionKey === act.label.text);

  const {
    fallback,
    target,
    disabled,
    display_instantly,
    trigger_action: triggerAction,
    intervention_id: interventionId,
    track,
  } = action;

  // Datadog config
  const statsdConfig = {
    keyPrefix: VPP_SEQUENCER_DATADOG_KEY_PREFIX,
    baseTags: {
      button_type: triggerAction || UNKNOWN_VALUE,
      referer_app: app,
      is_disabled: Boolean(disabled),
      intervention_id: interventionId || UNKNOWN_VALUE,
    },
  };

  return {
    action,
    actions,
    actionKey,
    target,
    fallback,
    display_instantly,
    track,
    siteId,
    statsdConfig,
    preload,
  };
};

export { getTriggerBuyNowOnePayForAll, getTriggerSequencer };
