const getFetchCompatibleValues = (domainId, labelId, values, getState) => {
  const { app, components = {} } = getState();
  const { compatibilities } = components;
  return { app, compatibilities, domainId, labelId, values };
};

const getFetchCompatibilities = (domainId, values, getState) => {
  const { app, id: itemId } = getState();
  return { app, itemId, domainId, values };
};

export { getFetchCompatibleValues, getFetchCompatibilities };
