import React, { useState, useEffect, useContext } from 'react';

import { string, bool, shape, oneOf, func } from 'prop-types';
import classnames from 'classnames';

import { Message as AndesMessage } from '@andes/message';
import { targetHostnameValidator } from '@vpp-frontend-components/common';

import MediaAction from '../media/media-action';
import StaticPropsContext from '../context/static-props';
import { validateSnackbarColor } from '../../utils/validators';
import {
  getParentMessageRedirectFrom,
  getSimilarMessageRedirectFrom,
  getSimilarLinkMessageRedirectFrom,
} from '../../lib/get-redirect-from-message';

const REDIRECT_FROM_PARENT_HASH = '#redirectedFromParent';
const REDIRECT_FROM_SIMILAR_HASH = '#redirectedFromSimilar';
const REDIRECT_FROM_SIMILAR_LINK_HASH = /^#redirectedFromSimilar=(.*)/;
const REDIRECT_FROM_TYPE_PARENT = 'parent';
const REDIRECT_FROM_TYPE_SIMILAR = 'similar';
const REDIRECT_FROM_TYPE_SIMILAR_LINK = 'similar-link';

const namespace = 'ui-pdp-message';

const RedirectedFromMessage = ({
  type,
  title,
  hierarchy,
  closeable,
  className,
  redirect_context,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { siteId } = useContext(StaticPropsContext);
    const [showRedirectedFromMessage, setShowRedirectedFromMessage] = React.useState(null);
    const [url, setUrl] = useState(null);
    const [isValidDomain, setIsValidDomain] = useState(false);

    let content = null;

    useEffect(() => {
      if (redirect_context) {
        if (window.location.hash === REDIRECT_FROM_PARENT_HASH) {
          if (redirect_context.parent_permalink) {
            setShowRedirectedFromMessage(REDIRECT_FROM_TYPE_PARENT);
            const urlRedirect = redirect_context.parent_permalink;
            setUrl(urlRedirect);
            const isValidUrl = targetHostnameValidator({
              target: redirect_context.parent_permalink,
              currentLocation: window.location,
            });
            setIsValidDomain(isValidUrl);
          }
        } else if (window.location.hash === REDIRECT_FROM_SIMILAR_HASH) {
          setShowRedirectedFromMessage(REDIRECT_FROM_TYPE_SIMILAR);
        } else {
          const similarWithId = REDIRECT_FROM_SIMILAR_LINK_HASH.exec(window.location.hash);
          if (similarWithId && redirect_context.similar_permalink) {
            const decodedUri = decodeURIComponent(similarWithId[1]);
            setShowRedirectedFromMessage(REDIRECT_FROM_TYPE_SIMILAR_LINK);
            const urlRedirect = `${decodedUri}${redirect_context.similar_permalink}`;
            setUrl(urlRedirect);
            const isValidUrl = targetHostnameValidator({
              target: `${decodedUri}${redirect_context.similar_permalink}`,
              currentLocation: window.location,
            });
            setIsValidDomain(isValidUrl);
          }
        }
      }
    }, [redirect_context]);

    if (showRedirectedFromMessage === REDIRECT_FROM_TYPE_PARENT) {
      content = getParentMessageRedirectFrom(siteId);
    } else if (showRedirectedFromMessage === REDIRECT_FROM_TYPE_SIMILAR) {
      content = getSimilarMessageRedirectFrom(siteId);
    } else if (showRedirectedFromMessage === REDIRECT_FROM_TYPE_SIMILAR_LINK) {
      content = getSimilarLinkMessageRedirectFrom(siteId);
    }

    if (!redirect_context || !showRedirectedFromMessage || !content) {
      return null;
    }

    return (
      redirect_context && (
        <AndesMessage
          className={classnames(namespace, className, 'ui-vpp-message')}
          color={validateSnackbarColor(type)}
          hierarchy={hierarchy}
          closable={closeable}
          title={title}
        >
          {content.label}
          {content.link && url && isValidDomain && (
            <MediaAction className={`${namespace}__link`} label={{ text: content.link }} target={url} />
          )}
        </AndesMessage>
      )
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

RedirectedFromMessage.propTypes = {
  className: string,
  type: oneOf(['neutral', 'success', 'warning', 'error']),
  title: shape({ text: string.isRequired }),
  hierarchy: string,
  closeable: bool,
  redirect_context: shape({ parent_permalink: string, similar_permalink: string }),
  runCatchErrorBoundary: func,
};

RedirectedFromMessage.defaultProps = {
  className: null,
  title: null,
  type: 'warning',
  hierarchy: 'quiet',
  closeable: false,
  redirect_context: null,
  runCatchErrorBoundary: () => {},
};

export default RedirectedFromMessage;
