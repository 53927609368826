import APIService from '../../../services/api';
import {
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR,
  FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START,
  FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE,
  FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR,
  FETCH_COMPATIBLE_POSIBLE_VALUES_START,
} from '../../vip/types';

const execFetchCompatibleValues = (dispatch, args) => {
  const { app, compatibilities, domainId, labelId, values } = args;
  dispatch({ type: FETCH_COMPATIBLE_POSIBLE_VALUES_START, domainId, labelId });
  return (
    APIService.getCompatibleValues(domainId, labelId, values, { app })
      // eslint-disable-next-line promise/always-return
      .then(response => {
        const inputs = compatibilities.inputs.map(input => {
          if (input.label.id === labelId) {
            input.values = response.label_values;
          }
          return input;
        });

        dispatch({ type: FETCH_COMPATIBLE_POSIBLE_VALUES_COMPLETE, labelId, inputs, message: response.message });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMPATIBLE_POSIBLE_VALUES_ERROR, error });
      })
  );
};

const execFetchCompatibilities = (dispatch, args) => {
  const { app, itemId, domainId, values } = args;
  dispatch({ type: FETCH_COMPATIBILITIES_WITH_USER_SELECTION_START, domainId });
  return (
    APIService.searchCompatibilities(itemId, domainId, values, { app })
      // eslint-disable-next-line promise/always-return
      .then(payload => {
        dispatch({ type: FETCH_COMPATIBILITIES_WITH_USER_SELECTION_COMPLETE, payload });
      })
      .catch(error => {
        dispatch({ type: FETCH_COMPATIBILITIES_WITH_USER_SELECTION_ERROR, error });
      })
  );
};

export { execFetchCompatibleValues, execFetchCompatibilities };
