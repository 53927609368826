import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import withRow from '../../../../components/grid/row/with-row';
import jsonToProps from '../generic-summary/shipping-mapper';

const FEATURE_NAME = 'special_event_shipping_summary';

const mapStateToProps = ({ components: { [FEATURE_NAME]: shippingSummary }, isFetching }) =>
  jsonToProps({ ...shippingSummary, isFetching, id: FEATURE_NAME });

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  withRow(FEATURE_NAME),
  connect(mapStateToProps),
);

const shippingSummary = ShippingSummary => enhance(ShippingSummary);

export default shippingSummary;
