import APIService from '../../../services/api';
import { FETCH_COMPLETE, FETCH_ERROR } from '../../vip/types';

const execFetchComponents = (dispatch, args, setIsLoading) => {
  const { id, params, app, vip_filters, variation_id } = args;
  setIsLoading(true);
  APIService.getItem({ ...params, id, app, vip_filters, trigger_variation_id: variation_id })
    .then(payload => {
      setIsLoading(false);
      dispatch({ type: FETCH_COMPLETE, payload: { attributes: params.attributes, ...payload } });
    })
    .catch(e => {
      setIsLoading(false);
      dispatch({ type: FETCH_ERROR, error: e });
    });
};

export { execFetchComponents };
