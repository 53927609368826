const getAddToCartUpdate = (action, quantity, target, onSuccess, onError, getState) => {
  const { app } = getState();

  const data = {
    action,
    quantity,
    ...target,
  };

  const params = { app };
  return { data, params, onSuccess, onError };
};

export { getAddToCartUpdate };
