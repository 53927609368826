import React, { useState, useEffect } from 'react';
import { bool, string, func, oneOf } from 'prop-types';
import classnames from 'classnames';
import Message from '../../message';
import { validateSnackbarColor } from '../../../utils/validators';

const CompatsMessageFeedabak = ({
  type,
  text,
  title,
  hierarchy,
  closeable,
  bgColor,
  className,
  state,
  isOfficialStore,
  onClose,
  runCatchErrorBoundary,
}) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const [isVisible, setIsVisible] = useState(state === 'VISIBLE');
    const [buttonClose, setButtonClose] = useState(null);
    const [headerGroup, setHeaderGroup] = useState(null);

    useEffect(() => {
      setButtonClose(document.querySelector('.ui-pdp-container__row--compats-feedback .andes-message__close--neutral'));
      setHeaderGroup(document.querySelector('.ui-vip-grouped-header__header--with-compats'));
      const hiddenFeedback = {
        state: 'HIDDEN',
        id: 'compats_feedback',
      };

      const handleClose = () => {
        setIsVisible(false);
        onClose(hiddenFeedback);
        if (headerGroup) {
          headerGroup.classList.remove('ui-vip-grouped-header__header--with-compats');
        }
      };

      if (buttonClose) {
        buttonClose.addEventListener('click', () => handleClose());
      }
    }, [buttonClose, headerGroup, onClose]);

    return (
      isVisible && (
        <div
          className={classnames('ui-pdp-container__row', 'ui-pdp-container__row--compats-feedback', {
            'ui-pdp-compats--store': isOfficialStore,
          })}
        >
          <Message
            color={validateSnackbarColor(type)}
            text={text}
            title={title}
            hierarchy={hierarchy}
            closeable={closeable}
            bgColor={bgColor}
            className={className}
          />
        </div>
      )
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

CompatsMessageFeedabak.propTypes = {
  className: string,
  text: string,
  type: oneOf(['neutral', 'success', 'warning', 'error']),
  title: string,
  hierarchy: string,
  closeable: bool,
  bgColor: string,
  state: string,
  isOfficialStore: bool,
  onClose: func,
  runCatchErrorBoundary: func,
};

CompatsMessageFeedabak.defaultProps = {
  className: null,
  title: null,
  text: null,
  type: 'neutral',
  hierarchy: 'quiet',
  closeable: true,
  bgColor: null,
  state: 'HIDDEN',
  isOfficialStore: null,
  onClose: null,
  runCatchErrorBoundary: () => {},
};

export default CompatsMessageFeedabak;
