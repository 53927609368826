import get from 'lodash/get';
import { QUESTIONS_AI_DATADOG_KEY_PREFIX } from '../../../services/frontend-statsd/config/allowed-keys';
import { QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES } from '../../../services/frontend-statsd/config/allowed-tags';

const getOnCreateQuestionFromAi = (text, snackbar_message, suggestionTrack, source, getState) => {
  const { app, id: itemId, components } = getState();
  const track = get(components, 'questions.track', null);
  const origin = suggestionTrack?.melidata_event?.event_data?.origin || 'form';
  const referer_app = app;
  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      origin,
      referer_app,
      is_post_question_from_webview: false,
      source,
    },
  };
  return { app, itemId, text, track, snackbar_message, suggestionTrack, statsdConfig };
};

const getPostQuestionFromAi = (productId, itemId, text, track, attributes, quantity, source, getState) => {
  const { app } = getState();
  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      referer_app: app,
      source,
    },
  };
  return { app, productId, itemId, text, track, attributes, quantity, source, statsdConfig, getState };
};

const getIsUseful = (itemId, useful, id, response, track, source, getState) => {
  const { app } = getState();
  // Datadog config
  const statsdConfig = {
    keyPrefix: QUESTIONS_AI_DATADOG_KEY_PREFIX,
    baseTags: {
      button_type: QUESTIONS_AI_BUTTON_TYPE_TAG_VALUES.FEEDBACK,
      source,
    },
  };
  return { app, itemId, useful, id, response, track, statsdConfig };
};

export { getOnCreateQuestionFromAi, getPostQuestionFromAi, getIsUseful };
