import React, { useState } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import classnames from 'classnames';
import { Form } from '@andes/form';
import { AndesProvider } from '@andes/context';
import { Button } from '@andes/button';
import Dropdown, { DropdownItem } from '@andes/dropdown';
import { Table, TableBody, TableHead, TableHeader, TableRow, TableData } from '@andes/table';
import ActionModal from '../action-modal';
import { ActionType, TableType, LabelType, MessageType, InputType } from './types';
import CompatibilitiesMessageComponent from './compatibilities-message';

const namespace = 'ui-pdp-compatibilities';

const CompatibilitiesModalComponent = ({
  buttonLabel,
  modalUrl,
  modalTitle,
  description,
  helpText,
  action,
  userSelection,
  table,
  inputs,
  message,
  success,
  onOpen,
  onChange,
  onSubmit,
  isFetching,
}) => {
  const [item, setItem] = useState({});

  const executeChange = () => {
    onChange(item.input, item.value);
  };

  const handleChange = (input, optionValue) => {
    let value;

    for (let index = 0; index < input.values.length; index += 1) {
      const option = input.values[`${index}`];
      if (option.value === optionValue) {
        value = option;
      }
    }

    const inputValues = {
      input: input.label.id,
      value,
    };

    setItem(inputValues);
    onChange(inputValues.input, inputValues.value);
  };

  const disabled = inputs.length === 0 || inputs.filter(input => input.required && !input.selected_value).length > 0;

  return (
    <ActionModal
      messageId="compatibilities-modal"
      label={buttonLabel}
      linkClassName={`andes-button andes-button--transparent ${namespace}__link`}
      onOpenModal={onOpen}
      modalClassName={classnames(`${namespace}__modal`)}
      modalUrl={modalUrl}
      modalTitle={modalTitle}
      autoHeight={false}
      closeModalLabel={action.close_modal_label}
    >
      <p className={`${namespace}__modal-description`}>{description}</p>
      <AndesProvider>
        <Form method="POST" className={`${namespace}__form`} onSubmit={onSubmit} data-testid="form">
          {inputs.map(input => (
            <Dropdown
              key={`dropdown-${input.label.id}`}
              className={classnames(`${namespace}__input`, `${namespace}__input-${input.label.id}`, {
                [`${namespace}__input--required`]: input.required,
              })}
              label={input.label.text}
              data-testid={`dropdown-${input.label.id}`}
              type="form"
              menuAlignment="bottom"
              onChange={(event, optionValue) => handleChange(input, optionValue)}
              value={input.selected_value ? input.selected_value.value : null}
              disabled={!input.values || input.values.length === 0}
            >
              {input.values &&
                input.values.map(option => (
                  <DropdownItem key={option.value} title={option.value} value={option.value} />
                ))}
            </Dropdown>
          ))}
          <Button
            className={`${namespace}__submit`}
            type="submit"
            disabled={disabled}
            data-testid="submit"
            loading={isFetching}
          >
            {action.label.text}
          </Button>
        </Form>
      </AndesProvider>

      {!success && !userSelection && <p className={`${namespace}__modal-help_text`}>{helpText}</p>}

      <CompatibilitiesMessageComponent message={message} userSelection={userSelection} onChange={executeChange} />

      {table.rows.length > 0 && (
        <Table className={`${namespace}__results`} sticky>
          <TableHead>
            {table.headers.map(header => (
              <TableHeader key={`header-${header}`}>{header}</TableHeader>
            ))}
          </TableHead>
          <TableBody>
            {table.rows.map((row, index) => (
              <TableRow
                key={`row-${row.map(col => `${col.key}:${col.value}`).join('-')}`}
                className={classnames({ [`${namespace}__row--even`]: index % 2 > 0 })}
              >
                {row.map(col => (
                  <TableData key={`col-${col.key}`} title={col.key}>
                    {col.value}
                  </TableData>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </ActionModal>
  );
};

CompatibilitiesModalComponent.propTypes = {
  buttonLabel: LabelType.isRequired,
  modalUrl: string,
  modalTitle: string.isRequired,
  description: string.isRequired,
  helpText: string.isRequired,
  action: ActionType.isRequired,
  inputs: arrayOf(InputType),
  userSelection: string,
  success: bool,
  onOpen: func,
  onChange: func,
  onSubmit: func,
  table: TableType,
  message: MessageType,
  isFetching: bool,
};

CompatibilitiesModalComponent.defaultProps = {
  modalUrl: null,
  userSelection: null,
  message: null,
  success: null,
  inputs: [],
  table: {
    headers: [],
    rows: [],
  },
  onOpen: () => null,
  onChange: () => null,
  onSubmit: () => null,
  isFetching: false,
};

export default CompatibilitiesModalComponent;
