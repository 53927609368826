import getSelectedAttributes from '../../utils/get-selected-attributes';

const getFetchItemOnShippingUpdate = (shippingOptionId, getState) => {
  const { id, platform, app, filters: pdp_filters, vip_filters, components } = getState();
  const { available_quantity, bulk_sale_quantity } = components;
  const quantity = available_quantity?.picker?.selected ?? bulk_sale_quantity?.selected;
  return {
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    shippingOptionId,
  };
};

const getFetchShippingCalculatorOptions = getState => {
  const { id, destinationValue, components } = getState();
  const attributes = getSelectedAttributes(components);
  return { id, destinationValue, attributes };
};

export { getFetchItemOnShippingUpdate, getFetchShippingCalculatorOptions };
