import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { ActionType, InputType, ModalType, MessageType } from './types';
import CompatibilitiesComponent from './compatibilities-component';

const CompatibilitiesContainer = ({
  className,
  id,
  title,
  description,
  feature,
  action,
  modal,
  compatible_domain_id: domainId,
  fetchCompatibleValues,
  setSelectedValue,
  inputs,
  message,
  fetchCompatibilities,
  isFetching,
  success,
  runCatchErrorBoundary,
}) => {
  /* istanbul ignore next */
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const [shouldCloseModal, setShouldCloseModal] = useState(false);

    const handleOnFormChange = (labelId, value) => {
      setSelectedValue({ labelId, value });

      const labelIds = inputs.map(input => input.label.id);
      const labelIndex = labelIds.indexOf(labelId);

      // Reset subsequent input values
      const finalInputs = inputs.map((input, index) => {
        if (index > labelIndex) {
          input.values = [];
          setSelectedValue({ labelId: input.label.id, value: null });
        }
        return input;
      });

      const nextLabelId = labelIds[labelIds.indexOf(labelId) + 1];
      if (nextLabelId) {
        fetchCompatibleValues({
          domainId,
          labelId: nextLabelId,
          values: finalInputs.map(input => input.selected_value).filter(x => x),
        });
      }
    };

    const handleOnModalOpen = () => {
      if (!inputs[0].selected_value) {
        fetchCompatibleValues({ domainId, labelId: inputs[0].label.id });
      }
    };

    const handleOnFormSubmit = event => {
      event.preventDefault();
      fetchCompatibilities({
        domainId,
        values: inputs.map(input => input.selected_value).filter(x => x),
      });

      setShouldCloseModal(true);
    };

    useEffect(() => {
      // TODO: see a better way to use in a more react way
      //   The idea is to close the modal when the results are none or exactly one.
      if (!isFetching && success && modal.message && modal.message.type === 'ERROR' && shouldCloseModal) {
        const closeButton = document.querySelector('.andes-modal__close-button');
        if (closeButton) {
          setShouldCloseModal(false);
          closeButton.click();
        }
      }
    }, [isFetching, success, shouldCloseModal, modal.message]);

    return (
      <CompatibilitiesComponent
        className={className}
        id={id}
        title={title}
        description={description}
        feature={feature}
        action={action}
        modal={modal}
        inputs={inputs}
        message={message}
        success={success}
        onModalOpen={handleOnModalOpen}
        onFormChange={handleOnFormChange}
        onFormSubmit={handleOnFormSubmit}
        isFetching={isFetching}
      />
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

CompatibilitiesContainer.propTypes = {
  className: string,
  id: string,
  title: string.isRequired,
  description: string.isRequired,
  feature: string.isRequired,
  compatible_domain_id: string.isRequired,
  action: ActionType.isRequired,
  modal: ModalType.isRequired,
  inputs: arrayOf(InputType),
  message: MessageType,
  isFetching: bool,
  success: bool,
  fetchCompatibleValues: func,
  setSelectedValue: func,
  fetchCompatibilities: func,
  runCatchErrorBoundary: func,
};

/* istanbul ignore next */
CompatibilitiesContainer.defaultProps = {
  id: null,
  className: null,
  isFetching: false,
  success: null,
  message: null,
  inputs: [],
  fetchCompatibleValues: () => null,
  setSelectedValue: () => null,
  fetchCompatibilities: () => null,
  runCatchErrorBoundary: () => {},
};

export default CompatibilitiesContainer;
