const getOnCreateQuestionsUnregistered = (
  component,
  action,
  itemId,
  form,
  outPutValues,
  hasErrors,
  recaptchaV2Token,
  track,
  origin,
  recaptchaSiteKey,
  getState,
) => {
  const { app } = getState();
  return {
    app,
    component,
    action,
    itemId,
    form,
    outPutValues,
    hasErrors,
    recaptchaV2Token,
    track,
    origin,
    recaptchaSiteKey,
  };
};

const getFetchModalQuestions = (itemId, variationId, actionType, getState) => {
  const { app } = getState();
  return { app, itemId, variationId, actionType };
};

const getFetchModalUnregisteredQuestions = (itemId, variationId, getState) => {
  const { app } = getState();
  return { app, itemId, variationId };
};

const getOnCreateOnlyQuestion = (itemId, text, track, component, checkbox, getState) => {
  const { app, vertical } = getState();
  return { app, vertical, itemId, text, track, component, checkbox };
};

export {
  getFetchModalQuestions,
  getOnCreateQuestionsUnregistered,
  getFetchModalUnregisteredQuestions,
  getOnCreateOnlyQuestion,
};
