import React from 'react';
import { string, shape, func } from 'prop-types';
import { Button } from '@andes/button';
import classnames from 'classnames';
import { Image } from 'nordic/image';
import useTrackView from '../../hooks/use-track-view';

const namespace = 'ui-vpp-merch-ecosystem';

const MerchEcosystem = ({ runCatchErrorBoundary, ...props }) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    let track_view = {};

    if (props) {
      const { track } = props;
      track_view = track;
    }

    const refView = useTrackView(track_view.melidata_event, { threshold: 0, as_component_print: true });
    if (props) {
      const {
        title,
        content: { description, title: content_title, image },
        actions: action,
        className,
        target,
      } = props;

      return (
        title &&
        description && (
          <div ref={refView} key={namespace} className={classnames(namespace, className, `${namespace}__box`)}>
            {title && title.text && <p className={classnames(`${namespace}__title`)}>{title.text}</p>}
            <div className={classnames(namespace, `${namespace}__content`)}>
              {image && image.url && image.url.src && (
                <Image
                  className={classnames(`${namespace}__image`)}
                  lazyload="on"
                  lowEnd={false}
                  src={image.url.src}
                  alt={image.alt}
                />
              )}
              {content_title && content_title.text && (
                <p className={classnames(`${namespace}__content-title`)}>{content_title.text}</p>
              )}
              {description && description.text && (
                <p className={classnames(`${namespace}__description`)}>{description.text}</p>
              )}
            </div>
            <div className={classnames(namespace, `${namespace}__button-box`)}>
              {action && action.target && (
                <Button
                  className={classnames(`${namespace}__button`)}
                  hierarchy="quiet"
                  target={target}
                  href={action.target}
                  type="a"
                >
                  {action.label.text}
                  <span className="ui-pdp--hide">{action.accessibility_text}</span>
                </Button>
              )}
            </div>
          </div>
        )
      );
    }

    return null;
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

MerchEcosystem.propTypes = {
  className: string,
  title: shape().isRequired,
  content: shape().isRequired,
  actions: shape().isRequired,
  track: shape().isRequired,
  target: string,
  runCatchErrorBoundary: func,
};

MerchEcosystem.defaultProps = {
  className: '',
  target: '_blank',
  runCatchErrorBoundary: () => {},
};

export default MerchEcosystem;
