import APIService from '../../../services/api';
import {
  FETCH_ON_CHANGE_INSTALLATION_START,
  FETCH_ON_CHANGE_INSTALLATION_COMPLETE,
  FETCH_ERROR,
  FETCH_ON_CHANGE_INSTALLATION_END,
} from '../../vip/types';
import { showInstallationSnackbar } from '../../utils/installationsUtils';

const execUpdateInstallation = (dispatch, args) => {
  const { params, id } = args;
  dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_START, id });
  APIService.getInstallationsProduct(params)
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_COMPLETE, payload: { id, ...payload } });
      showInstallationSnackbar(payload, dispatch);
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_END });
    });
};

const execRemoveInstallation = (dispatch, args) => {
  const { params, id } = args;
  dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_START, id });
  APIService.getInstallationsProduct(params)
    .then(payload => {
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_COMPLETE, payload: { id, ...payload } });
      showInstallationSnackbar(payload, dispatch);
    })
    .catch(e => {
      dispatch({ type: FETCH_ERROR, error: e });
      dispatch({ type: FETCH_ON_CHANGE_INSTALLATION_END });
    });
};

export { execUpdateInstallation, execRemoveInstallation };
