import APIService from '../../../services/api';
import { trackEvent, trackEventWithCustomField, trackEventWithCustomFields } from '@vpp-frontend-components/common';
import {
  CREATE_QUESTION_COMPLETE,
  CREATE_QUESTION_COMPLETE_VIEW,
  CREATE_QUESTION_ERROR,
  CREATE_QUESTION_START,
  FETCH_ERROR,
  FETCH_MORE_QUESTIONS_COMPLETE_DESKTOP,
  FETCH_MORE_QUESTIONS_COMPLETE_MOBILE,
  FETCH_MORE_QUESTIONS_START,
} from '../../vip/types';

const execOnCreateQuestion = (dispatch, args) => {
  const { app, itemId, text, track } = args;
  dispatch({ type: CREATE_QUESTION_START, itemId });
  APIService.createQuestion(itemId, text, { app })
    .then(payload => {
      if (payload) {
        if (payload.stock_track) {
          trackEvent(payload.stock_track);
        }

        if (!payload.stock_modal) {
          const extraFields = payload.extra_post_event_data || {};
          extraFields.failed = !payload.success;
          trackEventWithCustomFields(track, extraFields);
        }

        if (payload.redirect) {
          window.location = payload.redirect.permalink;
        } else {
          dispatch({ type: CREATE_QUESTION_COMPLETE, payload });
        }
      }
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      dispatch({ type: CREATE_QUESTION_ERROR, error: e });
    });
};

const execOnCreateQuestionInView = (dispatch, args) => {
  const { app, itemId, text, track, make_only_question } = args;
  dispatch({ type: CREATE_QUESTION_START, itemId });
  APIService.createQuestion(itemId, text, { app, make_only_question })
    .then(payload => {
      if (payload.stock_track) {
        trackEvent(payload.stock_track);
      }

      if (!payload.stock_modal) {
        const extraFields = payload.extra_post_event_data || {};
        extraFields.failed = !payload.success;
        trackEventWithCustomFields(track, extraFields);
      }

      if (payload.redirect) {
        window.location = payload.redirect.permalink;
      } else {
        dispatch({ type: CREATE_QUESTION_COMPLETE_VIEW, payload });
      }
    })
    .catch(e => {
      trackEventWithCustomField('failed', track, true);
      dispatch({ type: CREATE_QUESTION_ERROR, error: e });
    });
};

const execFetchMoreQuestionsDesktop = (dispatch, args) => {
  dispatch({ type: FETCH_MORE_QUESTIONS_START });
  const { app, itemId, offset } = args;
  APIService.getItemQuestions(itemId, { offset, app })
    .then(payload => {
      dispatch({
        type: FETCH_MORE_QUESTIONS_COMPLETE_DESKTOP,
        payload,
      });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};
const execFetchMoreQuestionsMobile = (dispatch, args) => {
  dispatch({ type: FETCH_MORE_QUESTIONS_START });
  const { app, itemId, offset } = args;
  APIService.getItemQuestions(itemId, { offset, app })
    .then(payload => {
      dispatch({
        type: FETCH_MORE_QUESTIONS_COMPLETE_MOBILE,
        payload,
      });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

export {
  execOnCreateQuestion,
  execOnCreateQuestionInView,
  execFetchMoreQuestionsDesktop,
  execFetchMoreQuestionsMobile,
};
