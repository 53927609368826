const getterModelUnits = (modelId, deviceType, currentState, getState) => {
  const { app, id, quotation, picture_config } = getState();
  return { app, id, quotation, picture_config, modelId, deviceType, currentState };
};

const gettertPriceModelUnits = (modelId, deviceType, currentState, getState) => {
  const { app, id, quotation, picture_config } = getState();
  return { app, id, quotation, picture_config, modelId, deviceType, currentState };
};

const gettertUnitsLTR = (modelId, deviceType, currentState, getState) => {
  const { app, id, multifamily_modal, picture_config } = getState();
  return { app, id, multifamily_modal, picture_config, modelId, deviceType, currentState };
};

const getterFetchUnregisteredQuotations = (modelId, deviceType, currentState, getState) => {
  const { app, id, quotation, picture_config } = getState();
  return { app, id, quotation, picture_config, modelId, deviceType, currentState };
};

const getOnCreateQuotation = (unitId, recaptchaTokenV2, track, recaptchaSiteKey, getState) => {
  const { id, app } = getState();
  return { id, app, unitId, recaptchaTokenV2, track, recaptchaSiteKey };
};

const getOnShowPrice = (unitId, modelId, deviceType, currentState, recaptchaTokenV2, recaptchaSiteKey, getState) => {
  const { id, app } = getState();
  return { id, app, unitId, modelId, deviceType, currentState, recaptchaTokenV2, recaptchaSiteKey, getState };
};

const getOnCreateUnregisteredQuotation = (
  unitId,
  recaptchaTokenV2,
  form,
  track,
  recaptchaSiteKey,
  currentState,
  handleClose,
  getState,
) => {
  const { id, app } = getState();
  return { id, app, unitId, recaptchaTokenV2, form, track, recaptchaSiteKey, currentState, handleClose };
};

export {
  getterModelUnits,
  gettertPriceModelUnits,
  gettertUnitsLTR,
  getterFetchUnregisteredQuotations,
  getOnCreateQuotation,
  getOnShowPrice,
  getOnCreateUnregisteredQuotation,
};
