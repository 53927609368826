import APIService from '../../../services/api';
import { trackEvent } from '../../../lib/tracking';
import { FETCH_ON_SET_FOLLOW_COMPLETE, SHOW_SNACKBAR, TOGGLE_FOLLOW_OPTIMISTICALLY } from '../../vip/types';

const execToggleFollowSeller = (dispatch, args) => {
  const { params, followers } = args;
  dispatch({ type: TOGGLE_FOLLOW_OPTIMISTICALLY });
  if (followers.cta_status === 'do_follow') {
    trackEvent(followers.cta_do_follow.track_event);
    APIService.followSeller(followers.seller_id, params)
      .then(payload => {
        if (payload.status === 'ERROR') {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.snackbar?.message,
              type: payload.snackbar?.color,
              delay: payload.snackbar?.delay || 3000,
              called_from: 'follow_button',
            },
          });
        }
        dispatch({ type: FETCH_ON_SET_FOLLOW_COMPLETE, payload });
      })
      .catch(e => dispatch({ type: SHOW_SNACKBAR, error: e }));
  } else {
    trackEvent(followers.cta_stop_follow.track_event);
    APIService.unfollowSeller(followers.seller_id, params)
      .then(payload => {
        if (payload.status === 'ERROR') {
          dispatch({
            type: SHOW_SNACKBAR,
            params: {
              message: payload.snackbar?.message,
              type: payload.snackbar?.color,
              delay: payload.snackbar?.delay || 3000,
              called_from: 'follow_button',
            },
          });
        }
        dispatch({ type: FETCH_ON_SET_FOLLOW_COMPLETE, payload });
      })
      .catch(e => dispatch({ type: SHOW_SNACKBAR, error: e }));
  }
};

export { execToggleFollowSeller };
