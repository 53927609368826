import APIService from '../../../services/api';
import { FETCH_ERROR, FETCH_ON_SHIPPING_UPDATE_COMPLETE, FETCH_ON_SHIPPING_UPDATE_START } from '../../vip/types';

const execFetchItemOnShippingUpdate = (dispatch, args) => {
  const { id, platform, app, pdp_filters, vip_filters, quantity, shippingOptionId } = args;
  dispatch({
    type: FETCH_ON_SHIPPING_UPDATE_START,
    shippingOptionId,
  });

  APIService.getItem({
    id,
    platform,
    app,
    pdp_filters,
    vip_filters,
    quantity,
    shipping_option_id: shippingOptionId,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_SHIPPING_UPDATE_COMPLETE, payload, shippingOptionId });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

const execFetchShippingCalculatorOptions = (dispatch, args) => {
  const { id, destinationValue, attributes } = args;
  dispatch({
    type: FETCH_ON_SHIPPING_UPDATE_START,
  });
  APIService.getShippingCalculatorOptions({
    itemId: id,
    app: 'vpp',
    destination_value: destinationValue,
    attributes,
  })
    .then(payload => {
      dispatch({ type: FETCH_ON_SHIPPING_UPDATE_COMPLETE, payload, destinationValue });
    })
    .catch(e => dispatch({ type: FETCH_ERROR, error: e }));
};

export { execFetchItemOnShippingUpdate, execFetchShippingCalculatorOptions };
