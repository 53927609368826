import { OPERATION_FILL, OPERATION_REMOVE } from '../../../components/installation-service/installation.constants';

const getUpdateInstallation = (agency, getState) => {
  const { filters: pdp_filters, vip_filters, app, id, components } = getState();
  const { available_quantity, bulk_sale_quantity } = components;
  const quantity = available_quantity?.picker?.selected ?? bulk_sale_quantity?.selected;

  const params = {
    product_id: id,
    app,
    ...agency,
    quantity,
    operation: OPERATION_FILL,
    pdp_filters,
    vip_filters,
  };
  return { params, id };
};

const getRemoveInstallation = getState => {
  const { filters: pdp_filters, vip_filters, app, id, components } = getState();
  const { available_quantity, bulk_sale_quantity } = components;
  const quantity = available_quantity?.picker?.selected ?? bulk_sale_quantity?.selected;

  const params = {
    product_id: id,
    app,
    operation: OPERATION_REMOVE,
    quantity,
    pdp_filters,
    vip_filters,
  };
  return { params, id };
};

export { getUpdateInstallation, getRemoveInstallation };
